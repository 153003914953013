/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import set from 'lodash/set'
import camelCase from 'lodash/camelCase'
import PropTypes from 'prop-types'
import { defaultDarkTheme } from '../../../../constants.js'
import { EventStoryTemplate, Snapshot } from '@quintype/arrow'
import cloneDeep from 'lodash/cloneDeep'
import { addTargetBlankToExtLinks } from '../../../../../utils/add-target-blank-to-extlinks.js'
import { getUpdatedStoryConfig } from '../../../../../utils/story-utils.js'
import { detectComponent } from '../../../DetectComponent'
import { checkIfStoryPublic } from '../../../pages/login/sso-utils.js'
import { loadRelatedStories } from '../../related-stories-helper.js'
import PaywallStory from '../../paywall-story.js'
import PublicStory from '../../public-story.js'

import './story.m.css'

const StoryBase = props => {
  const {
    story,
    config,
    storyStoreField,
    preview,
    adComponent,
    widgetComp,
    storyBaseType,
    templateConfig,
    enableDarkMode,
    showSnapshotTemplate,
    firstStory
  } = props
  const isAccesstypeEnabled = get(
    config,
    ['pagebuilder-config', 'general', 'accesstypeIntegration', 'enableAccesstype'],
    false
  )
  const storyPageType = `${camelCase(story['story-template'])}-story`
  const isStoryPublic = !isAccesstypeEnabled || checkIfStoryPublic(story)
  const storyWrapperElement = useRef(null)
  set(story, ['firstStory'], firstStory)

  useEffect(() => {
    addTargetBlankToExtLinks(storyWrapperElement.current)
  }, [])

  const storyElementsConfig = {
    ...get(config, ['pagebuilder-config', 'story', 'globalStoryElements'], {}),
    jsEmbed: { lazyLoad: get(config, ['pagebuilder-config', 'general', 'others', 'lazyLoadJsEmbed'], true) }
  }

  const isStoryInfiniteScrollEnabled =
    get(config, ['pagebuilder-config', 'infiniteScroll', 'infiniteScrollType'], 'disabled') !== 'disabled'

  const storyConfig = getUpdatedStoryConfig({
    config,
    templateConfig,
    storyPageType,
    storyStoreField,
    story,
    enableDarkMode
  })

  const disableServiceWorkerCache = get(
    config,
    ['pagebuilder-config', 'general', 'serviceWorker', 'disableCache'],
    false
  )

  const adSlotComponent = adComponent || detectComponent('ad', isStoryInfiniteScrollEnabled, disableServiceWorkerCache)
  const widgetSlotComponent =
    widgetComp || detectComponent('widget', isStoryInfiniteScrollEnabled, disableServiceWorkerCache)
  const newStoryConfig = cloneDeep(storyConfig)
  if (enableDarkMode) {
    set(newStoryConfig, ['theme'], get(newStoryConfig, ['darkTheme'], defaultDarkTheme))
  }
  const bgColor = get(newStoryConfig, ['theme'])
  if (showSnapshotTemplate) {
    return (
      <div style={{ backgroundColor: bgColor }}>
        <div ref={storyWrapperElement} styleName='wrapper'>
          <Snapshot story={story} config={newStoryConfig} storyElementsConfig={storyElementsConfig} />
        </div>
      </div>
    )
  }

  const renderStoryTemplate = () => {
    if (story['story-template'] === 'events') {
      return (
        <EventStoryTemplate
          story={story}
          config={newStoryConfig}
          storyElementsConfig={storyElementsConfig}
          adComponent={adSlotComponent}
          widgetComp={widgetSlotComponent}
          enableDarkMode={enableDarkMode}
          loadRelatedStories={loadRelatedStories}
        />
      );
    } else if (isStoryPublic) {
      return (
        <PublicStory
          story={story}
          config={config}
          templateConfig={newStoryConfig}
          preview={preview}
          storyElementsConfig={storyElementsConfig}
          adComponent={adSlotComponent}
          widgetComp={widgetSlotComponent}
          storyBaseType={storyBaseType}
          enableDarkMode={enableDarkMode}
          loadRelatedStories={loadRelatedStories}
        />
      );
    } else {
      return (
        <div id='paywall-story'>
          <PaywallStory
            {...props}
            templateConfig={newStoryConfig}
            preview={preview}
            storyElementsConfig={storyElementsConfig}
            adComponent={adSlotComponent}
            widgetComp={widgetSlotComponent}
            storyBaseType={storyBaseType}
            enableDarkMode={enableDarkMode}
            loadRelatedStories={loadRelatedStories}
          />
        </div>
      );
    }
  };

  return (
    <div style={{ backgroundColor: bgColor || 'initial' }}>
      <div ref={storyWrapperElement} styleName='wrapper'>
        {renderStoryTemplate()}
      </div>
    </div>
  );
}

StoryBase.propTypes = {
  story: PropTypes.object,
  config: PropTypes.object,
  preview: PropTypes.bool,
  templateConfig: PropTypes.object,
  storyBaseType: PropTypes.string,
  storyStoreField: PropTypes.string,
  storyPageType: PropTypes.string,
  adComponent: PropTypes.func,
  widgetComp: PropTypes.func,
  enableDarkMode: PropTypes.bool,
  showSnapshotTemplate: PropTypes.node
}

StoryBase.defaultProps = {
  templateConfig: {}
}

const mapStateToProps = (state, props) => ({
  config: get(state, ['qt', 'config'], {})
})

export const Story = connect(mapStateToProps)(StoryBase)

export default Story
